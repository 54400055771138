import React, { useContext, useEffect, useState } from 'react'
import { Input } from '../../components/common components/Input'
import { Radio } from '../../components/common components/Radio'
import { Datepicker } from '../../components/common components/Datepicker'
import { CheckBox } from '../../components/common components/Checkbox'
import { Autocomplete, TextField, Grid, Card, CardContent, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { CREATE_ELEMENT } from './SalesPromotionData'
import { updateNewSalesPromotion } from '../../contractManagement/thunks/updateNewSalesPromotion'
import swal from 'sweetalert';
import authContext from '../../context/auth-context'
import SpinnerLoader from '../bookingConfirmation/Utils/spinner'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from "../../components/common components/Select";
import { Button } from "../../components/common components/Button";
import moment from "moment";
import { Label } from "../../components/common components/Label";
import { postFetchAllSalesPromotion } from '../../contractManagement/thunks/postFetchAllSalesPromotion'
import SaleList from '../../appStore/actions/saleList'
import { postDeleteSalesPromotion } from '../../contractManagement/thunks/postDeleteSalesPromotion'
import { Dialog } from '@material-ui/core'
import { CommonAutocomplete } from '../../components/common components/Autocomplete'
import { setEditSeat } from '../bookingConfirmation/ActionCreator/itinenary'
import { type } from 'os'

const SalesEdit = () => {
    const getSalespromotion = async(naID) => {
        if(naID){
            const particularSalesPromotion = await postFetchAllSalesPromotion({'naID': naID})
            return particularSalesPromotion;
        }
        return undefined
    }
    const dispatch = useDispatch();
    const history = useHistory();
    // let SaleListData = useSelector((state) => state.SaleListDataForget.saleList); 
    const location = useLocation()
    const {pathname} = location;
    const [isEdit, setIsEdit] = useState(()=>pathname?.split("/")?.pop() != 'NewSales')
    const [isEditable, setIsEditable] = useState(()=>pathname?.split("/")?.pop() != 'NewSales')
    const salesNaID = pathname?.split("/")?.pop() != "NewSales" ? pathname?.split("/")?.pop() : undefined;
    let localsalesToDisplay = {};
   
    const [consortiumInclusion, setConsortiumInclusion] = useState(null)
    const [consortiumExclusion, setConsortiumExclusion] = useState(null)
    
    const [salesToDisplay, setSalesToDisplay] = useState(()=>localsalesToDisplay)
    
    const [selectedValueReservationDates, setSelectedValueReservationDates] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedValue2, setSelectedValue2] = useState('');
    const [selectedInnerValue, setSelectedInnerValue] = useState(null);

    //Booking channel checkBox
    const [onlineCheckBox, setOnlineCheckBox] = useState(false);
    const [callCenterCheckBox, setCallCenterCheckBox] = useState(false);
    // const [apiCheckBox, setApiCheckBox] = useState(false);
    const [apiB2BCheckBox, setApiB2BCheckBox] = useState(false);
    const [apiB2CCheckBox, setApiB2CCheckBox] = useState(false);


    //Supplier checkBox
    const [sabreCheckBox, setSabreCheckBox] = useState(false);
    const [amadeusCheckBox, setAmadeusCheckBox] = useState(false);
    const [ndcCheckBox, setNdcCheckBox] = useState(false);

    //carrier checkBox
    const [allTripTypesCheckBox, setAllTripTypesCheckBox] = useState(false);
    const [oneWayCheckBox, setOneWayCheckBox] = useState(false);
    const [roundTripCheckBox, setRoundTripCheckBox] = useState(false);
    const [openJawCheckBox, setOpenJawCheckBox] = useState(false);
    const [multiCityCheckBox, setMultiCityCheckBox] = useState(false);
    const [allCabinTypesCheckBox, setAllCabinTypesCheckBox] = useState(false);
    const [economyCheckBox, setEconomyCheckBox] = useState(false);
    const [premiumEconomyCheckBox, setPremiumEconomyCheckBox] = useState(false);
    const [businessCheckBox, setBusinessCheckBox] = useState(false);
    const [firstCheckBox, setFirstCheckBox] = useState(false);
    const [consortiumIncludeSome, setConsortiumIncludeSome] = useState("");
    const [consortiumIncludeSomeAgencyLocation, setConsortiumIncludeSomeAgencyLocation] = useState("");
    const [agencyLocationIncludeSome, setAgencyLocationIncludeSome] = useState("");
    const [agencyIncludeSome, setAgencyIncludeSome] = useState("");
    const [carrierIncludeExcludeSomeOrigins, setCarrierIncludeExcludeSomeOrigins] = useState("");
    const [carrierIncludeExcludeDestinations, setCarrierIncludeExcludeDestinations] = useState("");
    const [carrierIncludeExcludeBrandCode, setCarrierIncludeExcludeBrandCode] = useState("");
    const [reservationDateFrom, setReservationDateFrom] = useState(null);
    const [reservationDateTo, setReservationDateTo] = useState(null);
    const [consortiumValues, setConsortiumValues] = useState([])
    const [loader, setLoader] = useState(false)
    const [whichButton, setWhichButton] = useState('')
    const [popUpMessage, setPopUpMessage] = useState('')
    const [popUp, setPopUp] = useState(false)
    const { orgId } = useContext(authContext);
    const [wholePageLoader, setWholePageLoader] = useState(false)

    const [countryData, setCountryData] = useState([])
    const [countryValueInclusion, setCountryValueInclusion] = useState(null)
    const [countryValueExclusion, setCountryValueExclusion] = useState(null)
    const [stateValueInclusion, setStateValueInclusion] = useState(null)
    const [stateValueExclusion, setStateValueExclusion] = useState(null)
    const [states, setStates] = useState([])
    const [voidAllowed, setVoidAllowed] = useState(false);
    const [refundAllowed, setRefundAllowed] = useState(false);
    const [reissueAllowed, setReissueAllowed] = useState(false);
    const [sellPUBAtCost, setSellPUBAtCost] = useState(false);


    const { register, reset, handleSubmit,setValue, formState: {errors} } = useForm();

    useEffect(async() => {
        fetchData();
    }, [salesNaID]);

    useEffect(async() => {
        await fetch('https://countriesnow.space/api/v0.1/countries/iso', {
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {const convertedCountries = result.data.map(country => ({
                label: country.name,
                value: country.Iso2
            }));setCountryData(convertedCountries);})
            .catch(error => console.error("Error: ", error))

    }, []);

    useEffect(() => {
        async function fetchData() {
            const localsalesToDisplay = await getSalespromotion(salesNaID);
            if (isEdit) {
                try {
                    // Fetch countries
                    const response = await fetch('https://countriesnow.space/api/v0.1/countries/iso');
                    if (!response.ok) {
                        throw new Error('Failed to fetch countries');
                    }
                    const result = await response.json();
                    
                    // Map countries to required format
                    const convertedCountries = result.data.map(country => ({
                        label: country.name,
                        value: country.Iso2
                    }));
                    
                    setCountryData(convertedCountries);
                    
                    const countryInclusion = convertedCountries.find(country => country.value == localsalesToDisplay?.[0]?.AgencyLocationInclusion?.split('-')?.[0]);
                    const countryExclusion = convertedCountries.find(country => country.value === localsalesToDisplay?.[0]?.AgencyLocationExclusion?.split('-')?.[0]);
                    
                    let temp1 = [];
                    let temp2 = [];
                    if(countryInclusion?.label){temp1 = await getStates(countryInclusion?.label);}
                    if(countryExclusion?.label){temp2 = await getStates(countryExclusion?.label);}
                    
                    const stateInclusion = temp1?.find(state => state.value == localsalesToDisplay?.[0]?.AgencyLocationInclusion?.split('-')?.[1]);
                    const stateExclusion = temp2?.find(state => state.value === localsalesToDisplay?.[0]?.AgencyLocationExclusion?.split('-')?.[1]);
                    
                    setCountryValueInclusion(countryInclusion);
                    setCountryValueExclusion(countryExclusion);
                    setStateValueInclusion(stateInclusion);
                    setStateValueExclusion(stateExclusion);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        }
    
        fetchData();
    }, []);

    const CONSTANTS = {
        "INPUT": "INPUT",
        "SELECT": "SELECT",
        "CHECKBOX": "CHECKBOX",
        "RADIO": "RADIO",
        "BUTTON": "BUTTON",
        "DATEPICKER": "DATEPICKER",
        "LABEL": "LABEL"
    }
    
    const FORM_INPUTS = {
        [CONSTANTS.INPUT]: Input,
        [CONSTANTS.CHECKBOX]: CheckBox,
        [CONSTANTS.SELECT]: Select,
        [CONSTANTS.RADIO]: Radio,
        [CONSTANTS.BUTTON]: Button,
        [CONSTANTS.DATEPICKER]: Datepicker,
        [CONSTANTS.LABEL]: Label
    }

    // json config
    const SALES_EDIT_FORM_DATA = {
        "First Row": {
            key: "Specific Duration",
            className: "cm-col mt-3 d-flex alignCenter",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.LABEL],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        children:"Reservation Dates:",
                        className: "cm-head-label mr-6",
                        defaultValue: "",
                        name: "Consortium",
                        placeholder: "Consortium",
                        key: "Consortium",
                        type: "radio",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.RADIO],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        label:"No Restriction",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Consortium",
                        placeholder: "Consortium",
                        key: "Consortium",
                        type: "radio",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.RADIO],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        label:"Specific Duration",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Consortium",
                        placeholder: "Consortium",
                        key: "Consortium",
                        type: "radio",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                }
            ],
            children:[
                {
                    showKey: "SpecificDate",
                    component: FORM_INPUTS[CONSTANTS.DATEPICKER],
                    isChanged: false,
                    props: {
                        key: "reservationStartDate",
                        divClass: "cm-col m-0",
                        name: "reservationStartDate",
                        value: moment(new Date()).format("DD-MMM-yyyy"),
                        className:"BulkUpdate__DatePicker sales__DatePicker mr-3",
                        format:"yyyy-MM-dd",
                        defaultValue: "",
                        rules: {
                            required: true
                        }
                    },
                },
                {
                    showKey: "SpecificDate",
                    component: FORM_INPUTS[CONSTANTS.DATEPICKER],
                    isChanged: false,
                    props: {
                        divClass: " pt-8",
                        name: "reservationEndDate",
                        key: "reservationEndDate",
                        value: moment(new Date()).format("DD-MMM-yyyy"),
                        className:"BulkUpdate__DatePicker sales__DatePicker",
                        format:"yyyy-MM-dd",
                        defaultValue: "",
                        rules: {
                            required: true
                        }
                    },
                }
            ]
        },
        "Second Row": {
            children: [
                {
                    component: FORM_INPUTS[CONSTANTS.CHECKBOX],
                    isChanged: false,
                    props: {
                        label : "Online",
                        divClass: "cm-col",
                        children:"Reservation Dates:",
                        className: "cm-head-label mr-6",
                        defaultValue: "",
                        name: "Online",
                        placeholder: "Consortium",
                        key: "Online",
                        type: "radio",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Online',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.CHECKBOX],
                    isChanged: false,
                    props: {
                        label : "Call Center",
                        divClass: "cm-col",
                        children:"Reservation Dates:",
                        className: "cm-head-label mr-6",
                        defaultValue: "",
                        name: "Call Center",
                        placeholder: "Consortium",
                        key: "Call Center",
                        type: "radio",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Online',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.CHECKBOX],
                    isChanged: false,
                    props: {
                        label : "API",
                        divClass: "cm-col",
                        children:"Reservation Dates:",
                        className: "cm-head-label mr-6",
                        defaultValue: "",
                        name: "API",
                        placeholder: "Consortium",
                        key: "API",
                        type: "radio",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Online',
                        labelvisible:true
                    },
                }
            ]
        },
        "Third Row": {
            key: "Agency Location",
            className: "card mt-2 card-body pt-1 pb-4",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.LABEL],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        children:"Applicable To",
                        className: "cm-head-label mr-6",
                        defaultValue: "",
                        name: "Applicable To",
                        key: "Applicable To",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.RADIO],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        label:"Consortium",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Booking Channel",
                        placeholder: "Booking Channel",
                        key: "BookingChannel",
                        type: "radio",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.RADIO],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        label:"Supplier",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Supplier",
                        key: "Supplier",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.RADIO],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        label:"Carrier",
                        className: "form-control cm-input",
                        defaultValue: "",
                        name: "Carrier",
                        key: "Carrier",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                }
            ]
        },
        "Fourth Row": {
            key: "Agency",
            className: "cm-col mt-5 mb-2",
            className2: "card-body",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.LABEL],
                    isChanged: false,
                    props: {
                        divClass: "cm-col",
                        children:"Promotional Values",
                        className: "cm-head-label m-0",
                        defaultValue: "",
                        name: "Promotional Values",
                        key: "Promotional Values",
                        rules: {
                            required: true
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Consortium',
                        labelvisible:true
                    },
                }
            ]
        },
        "Fifth Row": {
            key: "",
            className: "card card-body",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        rsKey:"TicketingFees",
                        disabled:isEditable,
                        className: "form-control cm-input",
                        defaultValue: isEdit ? salesToDisplay?.TicketingFees : undefined,
                        name: "Ticketing Fee",
                        key: "TicketingFee",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Ticketing Fee',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        rsKey:"VoidFees",
                        disabled:isEditable,
                        className:"form-control cm-input",
                        defaultValue: isEdit ? salesToDisplay?.VoidFees : undefined,
                        name: "VoidFee",
                        key: "VoidFee",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Void Fee',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        rsKey:"RefundFees",
                        disabled:isEditable,
                        className: "form-control cm-input",
                        defaultValue: isEdit ? salesToDisplay?.RefundFees : undefined,
                        name: "refundFee",
                        key: "refundFee",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Refund Fee',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        rsKey: "ReissueFees",
                        disabled:isEditable,
                        className:"form-control cm-input",
                        defaultValue: isEdit ? salesToDisplay?.ReissueFees : undefined,
                        name: "ReissueFee",
                        key: "ReissueFee",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Reissue Fee',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        rsKey:"BookingFees",
                        disabled:isEditable,
                        className:"form-control cm-input",
                        defaultValue: isEdit ? salesToDisplay?.BookingFees : undefined,
                        name: "BookingFee",
                        key: "BookingFee",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Booking Fee',
                        labelvisible:true
                    },
                },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        rsKey:"MerchantFees",
                        disabled:isEditable,
                        className:"form-control cm-input",
                        defaultValue: isEdit ? salesToDisplay?.MerchantFees : undefined,
                        name: "MerchantFee",
                        key: "MerchantFee",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label',
                        labelName:'Merchant Fee',
                        labelvisible:true
                    },
                }
            ]
        },
        "Sixth Row": {
            key: "",
            className: "card card-body",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        disabled:isEditable,
                        divClass: "cm-col",
                        className:"form-control cm-input",
                        defaultValue: isEdit ? salesToDisplay?.AddorRemovePUBCommissionAmount : undefined,
                        name: "AddorRemovePUBcommissionAmount",
                        key: "AddorRemovePUBcommissionAmount",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label-smaller-font',
                        labelName:'Add or Remove PUB commission Amount',
                        labelvisible:true
                    },
                }
            ]
        },
        "Seventh Row": {
            key: "",
            className: "card card-body",
            data: [
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        disabled:isEditable,
                        divClass: "cm-col",
                        className: "form-control cm-input",
                        defaultValue:  isEdit ? salesToDisplay?.LowestofDefaultMarkuporthisvalue : undefined,
                        name: "LowestofDefaultMarkuporthisvalue",
                        key: "LowestofDefaultMarkuporthisvalue",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label-smaller-font',
                        labelName:'Lowest of Default Markup or this value',
                        labelvisible:true
                    }, 
                },
                {
                    component: FORM_INPUTS[CONSTANTS.INPUT],
                    isChanged: false,
                    props: {
                        disabled:isEditable,
                        divClass: "cm-col",
                        className:"form-control cm-input",
                        defaultValue:  isEdit ? salesToDisplay?.AddorRemoveFromDefaultMarkup : undefined,
                        name: "AddorRemovefromDefaultMarkup",
                        key: "AddorRemovefromDefaultMarkup",
                        rules: {
                            required: false
                        },
                        labelClassName:'cm-head-label-smaller-font',
                        labelName:'Add or Remove from Default Markup',
                        labelvisible:true
                    },
                }
            ]
        }
    };

    
    const onSubmit = async (data) => {
        setLoader(true)
            let bookingChannel = []
            // if(apiCheckBox){pushIfNotPresent(bookingChannel,'API')}
            if(callCenterCheckBox){pushIfNotPresent(bookingChannel,'CallCenter')}
            if(onlineCheckBox){pushIfNotPresent(bookingChannel,'online')}
            if(apiB2BCheckBox){pushIfNotPresent(bookingChannel,'API-B2B')}
            if(apiB2CCheckBox){pushIfNotPresent(bookingChannel,'API-B2C')}
            let supplier = []
            if(sabreCheckBox){pushIfNotPresent(supplier,'Sabre')}
            if(amadeusCheckBox){pushIfNotPresent(supplier,'Duffel')}
            if(ndcCheckBox){pushIfNotPresent(supplier,'SabreNDC')}
            let tripType = []
            if(allTripTypesCheckBox){tripType = ['OW', 'RT', 'OJ', 'MC']}
            if(oneWayCheckBox){pushIfNotPresent(tripType,'OW')}
            if(roundTripCheckBox){pushIfNotPresent(tripType,'RT')}
            if(openJawCheckBox){pushIfNotPresent(tripType,'OJ')}
            if(multiCityCheckBox){pushIfNotPresent(tripType,'MC')}
        let agencyInclusion;
        if (selectedValue === "Consortium") {
            agencyInclusion = (consortiumIncludeSome === "Include Agencies") ? data?.AgencyLocationInclusion : undefined;
        } else if (selectedValue === "Agency Location") {
            agencyInclusion = (agencyLocationIncludeSome === "Include Agencies") ? data?.AgencyInclusionExclusion : undefined;
        } else if (selectedValue === "Agency" && selectedInnerValue === 'Include Some') {
            agencyInclusion = data?.AgencyInclusion;
        } else {
            agencyInclusion = undefined;
        }

        let agencyExclusion;
        if (selectedValue === "Consortium") {
            agencyExclusion = (consortiumIncludeSome === "Exclude Agencies") ? data?.AgencyLocationInclusion : undefined;
        } else if (selectedValue === "Agency Location") {
            agencyExclusion = (agencyLocationIncludeSome === "Exclude Agencies") ? data?.AgencyInclusionExclusion : undefined;
        } else if (selectedValue === "Agency" && selectedInnerValue === 'Exclude Some') {
            agencyExclusion = data?.AgencyInclusion;
        } else {
            agencyExclusion = undefined;
        }

            let cabinType = []
            if(allCabinTypesCheckBox){cabinType = ['Economy', 'PremiumEconomy', 'Bussiness', 'First']}
            if(economyCheckBox){pushIfNotPresent(cabinType,'Economy')}
            if(premiumEconomyCheckBox){pushIfNotPresent(cabinType,'PremiumEconomy')}
            if(businessCheckBox){pushIfNotPresent(cabinType,'Bussiness')}
            if(firstCheckBox){pushIfNotPresent(cabinType,'First')}

            let payload = {
                'naID' : isEdit ? salesToDisplay?.naID : undefined,
                'reservationDateTo' : data?.ReservationDateTo,
                'reservationDateFrom' : data?.ReservationDateFrom,
                'consortiumInclusion' : selectedInnerValue === 'Include Some' ? data?.ConsortiumInclusion : undefined,
                'consortiumExclusion' :  selectedInnerValue === 'Exclude Some' ? data?.ConsortiumExclusion : undefined,
                "agencyInclusion" : agencyInclusion,
                "agencyExclusion" : agencyExclusion, 
                "agencyLocationInclusion" : selectedValue == "Agency Location"  ?  selectedInnerValue == "Include Some" ? `${countryValueInclusion?.value}-${stateValueInclusion?.value}` : undefined : selectedValue == "Consortium" ? data?.agencyLocationIncludeSome : undefined,
                "agencyLocationExclusion" : selectedValue == "Agency Location"  ?  selectedInnerValue === 'Exclude Some' ? `${countryValueExclusion?.value}-${stateValueExclusion?.value}` : undefined : undefined,
                "agentInclusion" : agencyIncludeSome == "Agnet Inclusion" ? data?.AgnetInclusionExclusion : undefined,
                "agentExclusion" : agencyIncludeSome == "Agnet Exclusion" ? data?.AgnetInclusionExclusion : undefined, 
                "bookingChannelInclusion" : selectedValue2 === 'Booking Channel' ? bookingChannel : undefined,
                "supplierInclusion" : selectedValue2 === 'Supplier' ? supplier : undefined,
                "originInclusion" : selectedValue2 === 'Carrier' ? carrierIncludeExcludeSomeOrigins == "Include Specific Origins" ? data?.originInclusionExclusion : undefined : undefined,
                "originExclusion" : selectedValue2 === 'Carrier' ? carrierIncludeExcludeSomeOrigins == "Exclude Specific Origins" ? data?.originInclusionExclusion : undefined: undefined,
                "destinationInclusion" : selectedValue2 === 'Carrier' ? carrierIncludeExcludeDestinations == "Include Destination(s)" ? data?.destinationInclusionExclusion : undefined : undefined,
                "destinationExclusion" : selectedValue2 === 'Carrier' ?  carrierIncludeExcludeDestinations == "Exclude Destination(s)" ? data?.destinationInclusionExclusion : undefined : undefined,
                "tripTypeInclusion" : selectedValue2 === 'Carrier' ? tripType : undefined,
                "brandedFareInclusion" : selectedValue2 === 'Carrier' ? carrierIncludeExcludeBrandCode == "Include Brand Code(s)" ? data?.BrandedFareInclusion : undefined : undefined,
                "brandedFareExclusion" : selectedValue2 === 'Carrier' ? carrierIncludeExcludeBrandCode == "Exclude Brand Code(s)" ? data?.BrandedFareInclusion : undefined : undefined,
                "ticketingFees" : data?.TicketingFee != "" ? data?.TicketingFee : undefined,
                "voidFees" : data?.VoidFee != "" ? data?.VoidFee : undefined,
                "refundFees" : data?.refundFee != "" ? data?.refundFee : undefined,
                "reissueFees" : data?.ReissueFee != "" ? data?.ReissueFee : undefined,
                "bookingFees" : data?.BookingFee != "" ? data?.BookingFee : undefined,
                "merchantFees" : data?.MerchantFee != "" ? data?.MerchantFee : undefined,
                "lowestOfDefaultMarkuporthisvalue" : data?.LowestofDefaultMarkuporthisvalue != "" ? data?.LowestofDefaultMarkuporthisvalue : undefined,
                "addOrRemoveFromDefaultMarkup" : data?.AddorRemovefromDefaultMarkup != "" ? data?.AddorRemovefromDefaultMarkup : undefined,
                "extraAgencyPUBCommission" : data?.ExtraAgencyPUBCommission != "" ? data?.ExtraAgencyPUBCommission : undefined,
                "addOrRemovePUBCommissionAmount" : data?.AddorRemovePUBcommissionAmount != "" ? data?.AddorRemovePUBcommissionAmount : undefined,
                "cabinType" : cabinType?.length ? cabinType : undefined,
                "adAllowCancellation": voidAllowed,
                "adAllowRefund": refundAllowed,
                "adAllowReissue": reissueAllowed,
                "sellPUBAtCost": sellPUBAtCost,
                "airline": data?.Airline != "" ? data?.Airline : undefined,
            }

        if (!isEmpty(clean(payload))) {
            if (!(countryValueInclusion?.value && stateValueInclusion?.value == undefined)) {
                const updateNewSalesPromotionApi = await updateNewSalesPromotion([clean(payload)])
                setLoader(false)
                if (salesNaID) { setIsEditable(true) }
            }
            else{
                swal({
                    text:"Please enter state.",
                    icon: "error",
                    button: "Close",
                  })
            }
        }
        else{
            swal({
                text:"Please enter at least one field.",
                icon: "error",
                button: "Close",
              })
        }
        if(!isEdit){
            resetState()
        }
    };

    function pushIfNotPresent(array, item) {
        if (!array.includes(item)) {
            array.push(item);
        }
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] == "") {
                delete obj[propName];
            }
        }
        return obj
    }

    const resetState = () => {
        reset({});
        setSelectedValueReservationDates(null)
        setSelectedValue('')
        setSelectedValue2('')
        setSelectedInnerValue(null)
        setOnlineCheckBox(false)
        setCallCenterCheckBox(false)
        // setApiCheckBox(false)
        setSabreCheckBox(false)
        setAmadeusCheckBox(false)
        setNdcCheckBox(false)
        setAllTripTypesCheckBox(false)
        setOneWayCheckBox(false)
        setRoundTripCheckBox(false)
        setOpenJawCheckBox(false)
        setMultiCityCheckBox(false)
        setAllCabinTypesCheckBox(false)
        setEconomyCheckBox(false)
        setPremiumEconomyCheckBox(false)
        setBusinessCheckBox(false)
        setFirstCheckBox(false)
        setConsortiumIncludeSome("")
        setConsortiumIncludeSomeAgencyLocation("")
        setAgencyLocationIncludeSome("")
        setAgencyIncludeSome("")
        setCarrierIncludeExcludeSomeOrigins("")
        setCarrierIncludeExcludeDestinations("")
        setCarrierIncludeExcludeBrandCode("")
        setConsortiumValues([])
        setLoader(false)
        setReservationDateFrom(String(new Date()))
        setReservationDateTo(String(new Date()))
        setConsortiumInclusion(null)
        setConsortiumExclusion(null)
    }

    const onChangeHandle = (value, fieldName) => {
        setValue(fieldName, value)
    }

    const handleRadioChange = (value) => {
        setSelectedValueReservationDates(value);
    };
    
    const handleInnerRadio = (value) => {
        setSelectedInnerValue(value);
    };

    const getConsortium = async() => {
        await fetch(`${process.env.REACT_APP_BASE_URL}/consortium/all?orgId=${orgId}`, {
            method: "POST",
            redirect: "follow",
          })
            .then((response) => response.json())
            .then((result) => setConsortiumValues(result.Items))
            .catch((error) => console.error("Error: ", error));
    }

    const postDeleteSalesPromotionCall = async(naID) => {
        setWholePageLoader(true)
        let deleteOpertionResult = await postDeleteSalesPromotion([naID])
        if(deleteOpertionResult?.message == "Sales Promotion Deleted!"){
            swal({
                text: deleteOpertionResult?.message,
                icon: "success",
                buttons: {
                    close: {
                        text: "Close",
                        closeModal: true,
                    },
                },
            })
        }else{
            swal({
                text:deleteOpertionResult?.message,
                icon: "error",
                button: "Close",
              })
        }
        setWholePageLoader(false)
        history.push('/SalesPromotion')
    }

    const fetchData = async () => {
        if (isEdit) {
            setWholePageLoader(true)
            const localsalesToDisplay = await getSalespromotion(salesNaID);
            setSalesToDisplay(trimObjectValues(localsalesToDisplay?.[0]));
            setSelectedValueReservationDates((localsalesToDisplay?.[0]?.ReservationDateFrom && localsalesToDisplay?.[0]?.ReservationDateTo) ? 'Specific Duration'  : 'No Restriction')
            setSelectedValue(((localsalesToDisplay?.[0]?.ConsortiumInclusion || localsalesToDisplay?.[0]?.ConsortiumExclusion) ? 'Consortium' : (localsalesToDisplay?.[0]?.AgencyLocationInclusion || localsalesToDisplay?.[0]?.AgencyLocationExclusion) ? 'Agency Location' : (localsalesToDisplay?.[0]?.AgencyInclusion || localsalesToDisplay?.[0]?.AgencyExclusion) ? 'Agency' : ''))
            setSelectedValue2(((localsalesToDisplay?.[0]?.BookingChannelInclusion) ? 'Booking Channel' : (localsalesToDisplay?.[0]?.SupplierInclusion) ? 'Supplier' : localsalesToDisplay?.[0]?.Airline ? 'Carrier' : ''))
            setSelectedInnerValue(((localsalesToDisplay?.[0]?.ConsortiumInclusion || localsalesToDisplay?.[0]?.AgencyLocationInclusion || localsalesToDisplay?.[0]?.AgencyInclusion) ? 'Include Some' : (localsalesToDisplay?.[0]?.ConsortiumExclusion || localsalesToDisplay?.[0]?.AgencyLocationExclusion || localsalesToDisplay?.[0]?.AgencyExclusion) ? 'Exclude Some' : null))
            setOnlineCheckBox(localsalesToDisplay?.[0]?.BookingChannelInclusion?.includes('online'))
            setCallCenterCheckBox(localsalesToDisplay?.[0]?.BookingChannelInclusion?.includes('CallCenter'))
            // setApiCheckBox(localsalesToDisplay?.[0]?.BookingChannelInclusion?.includes('API'))
            setApiB2BCheckBox(localsalesToDisplay?.[0]?.BookingChannelInclusion?.includes('API-B2B'))
            setApiB2CCheckBox(localsalesToDisplay?.[0]?.BookingChannelInclusion?.includes('API-B2C'))
            setSabreCheckBox(localsalesToDisplay?.[0]?.SupplierInclusion?.includes('Sabre'))
            setAmadeusCheckBox(localsalesToDisplay?.[0]?.SupplierInclusion?.includes('Duffel'))
            setNdcCheckBox(localsalesToDisplay?.[0]?.SupplierInclusion?.includes('SabreNDC'))
            setAllTripTypesCheckBox(localsalesToDisplay?.[0]?.TripTypeInclusion?.length == 4)
            setOneWayCheckBox(localsalesToDisplay?.[0]?.TripTypeInclusion?.includes('OneWay'))
            setRoundTripCheckBox(localsalesToDisplay?.[0]?.TripTypeInclusion?.includes('roundTrip'))
            setOpenJawCheckBox(localsalesToDisplay?.[0]?.TripTypeInclusion?.includes('openJaw'))
            setMultiCityCheckBox(localsalesToDisplay?.[0]?.TripTypeInclusion?.includes('multiCity'))
            setAllCabinTypesCheckBox(localsalesToDisplay?.[0]?.CabinType?.length == 4)
            setEconomyCheckBox(localsalesToDisplay?.[0]?.CabinType?.includes('Economy'))
            setPremiumEconomyCheckBox(localsalesToDisplay?.[0]?.CabinType?.includes('PremiumEconomy') || localsalesToDisplay?.[0]?.CabinType?.includes('Premium Economy'))
            setBusinessCheckBox(localsalesToDisplay?.[0]?.CabinType?.includes('Bussiness'))
            setFirstCheckBox(localsalesToDisplay?.[0]?.CabinType?.includes('First'))
            setConsortiumIncludeSome(localsalesToDisplay?.[0]?.AgencyInclusion ? 'Include Agencies' : localsalesToDisplay?.[0]?.AgencyExclusion ? 'Exclude Agencies' : "")
            setConsortiumIncludeSomeAgencyLocation(localsalesToDisplay?.[0]?.AgencyLocationInclusion ? 'Include Agencies Location' :"")
            setAgencyLocationIncludeSome(localsalesToDisplay?.[0]?.AgencyInclusion ? 'Include Agencies' : localsalesToDisplay?.[0]?.AgencyExclusion ? 'Exclude Agencies' : '')
            setAgencyIncludeSome(localsalesToDisplay?.[0]?.AgentExclusion ? 'Agnet Exclusion' : localsalesToDisplay?.[0]?.AgentInclusion ? 'Agnet Inclusion' : '')
            setCarrierIncludeExcludeSomeOrigins(localsalesToDisplay?.[0]?.OriginInclusion ? 'Include Specific Origins' : localsalesToDisplay?.[0]?.OriginExclusion ? 'Exclude Specific Origins' : '')
            setCarrierIncludeExcludeDestinations(localsalesToDisplay?.[0]?.DestinationInclusion ? 'Include Destination(s)' : localsalesToDisplay?.[0]?.DestinationExclusion ? 'Exclude Destination(s)' : '')
            setCarrierIncludeExcludeBrandCode(localsalesToDisplay?.[0]?.BrandedFareInclusion ? 'Include Brand Code(s)' : localsalesToDisplay?.[0]?.BrandedFareExclusion ? 'Exclude Brand Code(s)' : '')
            setReservationDateFrom(localsalesToDisplay?.[0]?.ReservationDateFrom)
            setReservationDateTo(localsalesToDisplay?.[0]?.ReservationDateTo)
            const consortiumInclusion = localsalesToDisplay[0]?.ConsortiumInclusion;
            const consortiumExclusion = localsalesToDisplay[0]?.ConsortiumExclusion;

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/consortium/all?orgId=${orgId}`, {
                    method: "POST",
                    redirect: "follow",
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                let filteredConsortiumInclusion;
                let filteredConsortiumExclusion;

                const result = await response.json();
                if(consortiumInclusion){ filteredConsortiumInclusion = result.Items.filter(obj => obj.consortiumName == consortiumInclusion);}
                if(consortiumExclusion){ filteredConsortiumExclusion = result.Items.filter(obj => obj.consortiumName == consortiumExclusion);}

                setConsortiumInclusion(filteredConsortiumInclusion?.[0]);
                setConsortiumExclusion(filteredConsortiumExclusion?.[0]);
            } catch (error) {
                console.error("Error fetching or processing data:", error);
            }
            setWholePageLoader(false)
            setVoidAllowed(localsalesToDisplay?.[0]?.AdAllowCancellation)
            setRefundAllowed(localsalesToDisplay?.[0]?.AdAllowRefund)
            setReissueAllowed(localsalesToDisplay?.[0]?.AdAllowReissue)
            setSellPUBAtCost(localsalesToDisplay?.[0]?.SellPUBAtCost)
        }
    };

    const openDialogBox = (whichButton, message ) => {
        setWhichButton(whichButton);
        setPopUpMessage(message)
        setPopUp(true);
    }

    
    
    async function getStates(country) {
        try {
            const response = await fetch(`https://countriesnow.space/api/v0.1/countries/states/q?country=${country}`, {
                method: "GET",
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch states');
            }
            
            const result = await response.json();
            
            const convertedStates = result?.data?.states.map(state => ({
                label: state.name,
                value: state.state_code
            }));
            
            setStates(convertedStates);
            
            return convertedStates;
        } catch (error) {
            console.error('Error fetching states:', error);
            return [];
        }
    }
    

    const onBackButton = () => {
        setIsEditable(true);
        setPopUp(false)
        fetchData()
    }

    const trimObjectValues = (obj) => {
        const trimmedObj = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (typeof value === 'string') {
              trimmedObj[key] = value.trim();
            } else {
              trimmedObj[key] = value;
            }
          }
        }
        return trimmedObj;
      }
      
    return (
        <>{wholePageLoader ?<div className=' d-flex sales-btn'> <SpinnerLoader />   </div>:<>
            <div className="cm-head-2">
                <div className="d-flex justify-content-between">
                    <span>Sales Promotion</span>
                    <span>
                        <button className='btn salesBackBtn' onClick={() => {if(isEdit? !isEditable : false){openDialogBox('backButton','Are you sure, changes will not be saved?')}else{history.push('/SalesPromotion')}}}> <i className='fas fa-angle-left'></i> Back</button>
                        {isEdit ?<> <button className='btn salesBackBtn' onClick={() => {setIsEditable(!isEditable)}}> <i className='fas fa-pen'></i> Edit</button>
                        <button hidden={isEdit ? true : isEdit} disabled={isEdit ? true : isEdit} className='btn salesBackBtn' onClick={() => {openDialogBox('deleteButton','Are you sure you want to delete this sales promotion?')}}> <i className='fas fa-trash-alt'></i> Delete</button></>:null}
                    </span>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className='cm-col mt-3 d-flex alignCenter'>
                <div>
                    <span className="cm-head-label mr-6"> Reservation Dates:</span>
                    <Radio disabled={isEditable} label='No Restriction' onChange={() => {setReservationDateFrom(null);setReservationDateTo(null);onChangeHandle('','ReservationDateFrom'); onChangeHandle('','ReservationDateTo');  handleRadioChange('No Restriction');}} checked={selectedValueReservationDates === 'No Restriction'} />
                    <Radio disabled={isEditable} label='Specific Duration' onChange={() => handleRadioChange('Specific Duration')} checked={selectedValueReservationDates === 'Specific Duration'} />
                </div>
                {selectedValueReservationDates === 'Specific Duration' &&
                    <div className='d-flex w-25'>
                        {/* <>
                        {SALES_EDIT_FORM_DATA["First Row"]?.children.map((data:any) => (
                            <>
                                {CREATE_ELEMENT(data, register)}
                            </>
                        ))}
                        </> */}
                        <Datepicker min={new Date().toISOString().split('T')[0]} disabled={isEditable} {...register('ReservationDateFrom')} className='BulkUpdate__DatePicker sales__DatePicker mr-3' key='ReservationDateFrom' value={reservationDateFrom || ''} onChange={(e) => {onChangeHandle(e?.target?.value,'ReservationDateFrom');setReservationDateFrom(new Date(e?.target?.value).toISOString().split('T')[0])}} />
                        <Datepicker min={reservationDateFrom} disabled={isEditable || reservationDateFrom==null} {...register('ReservationDateTo')} className='BulkUpdate__DatePicker sales__DatePicker' key='ReservationDateTo' value={reservationDateTo || ''} onChange={(e) => {onChangeHandle(e?.target?.value,'ReservationDateTo'); setReservationDateTo(new Date(e?.target?.value).toISOString().split('T')[0])}} />
                    </div>}
            </div>
            <div className='card'>
                <div className="card-body py-0">
                    <div className='cm-col m-0'>
                        <span className="cm-head-label mr-8">  Applicable To</span>
                        <Radio disabled={isEditable} label='Consortium' className='mr-4' onChange={() => setSelectedValue('Consortium')} checked={selectedValue === "Consortium"} />
                        <Radio disabled={isEditable} label='Agency Location' onChange={() => setSelectedValue('Agency Location')} checked={selectedValue === 'Agency Location'} />
                        <Radio disabled={isEditable} label='Agency' onChange={() => setSelectedValue('Agency')} checked={selectedValue === 'Agency'} />

                    </div>

                    {selectedValue === "Consortium" && isEdit ? (consortiumExclusion || consortiumInclusion || salesToDisplay?.AgencyInclusion|| salesToDisplay?.AgencyExclusion || salesToDisplay?.AgencyLocationInclusion) : true &&
                        <div className='d-flex salesEdit pb-5'>
                            <div>
                                <div className='salesEditcard '>
                                    <span className="sales-head-label"> Consortium </span>
                                </div>
                            </div>
                            <div>

                                {(isEdit ? (consortiumExclusion || consortiumInclusion) : true) && <div className='salesRight salesRadio d-flex alignCenter justify-between'>
                                    <div className='d-flex flex-column'>
                                        <Radio disabled={isEditable} {...register('ConsortiumInclusion')} label='Include Some' key='ConsortiumInclusionRadio' onChange={() => handleInnerRadio('Include Some')} checked={selectedInnerValue === 'Include Some'} />
                                        <Radio disabled={isEditable} label='Exclude Some' key='ConsortiumExclusionRadio' onChange={() => handleInnerRadio('Exclude Some')} checked={selectedInnerValue === 'Exclude Some'} />
                                    </div>

                                    {selectedInnerValue === 'Include Some' && 
                                        <div className='sale-AutoBox'>
                                                <Autocomplete
                                                    value={consortiumInclusion}
                                                    disabled={isEditable}
                                                    {...register('ConsortiumInclusion')}
                                                    key='ConsortiumInclusion'
                                                    limitTags={2}
                                                    id="multiple-limit-tags"
                                                    className='sales_autocomplete'
                                                    options={consortiumValues}
                                                    onFocus={getConsortium} // Assuming getConsortium is a function
                                                    getOptionLabel={(option) => option.consortiumName}
                                                    renderInput={(params) => <TextField {...params} label='Select' />}
                                                    onChange={(event, values) => {
                                                        const selectedNames = values?.consortiumName
                                                        onChangeHandle(selectedNames, 'ConsortiumInclusion');
                                                        setConsortiumInclusion(values);
                                                    }}
                                                />
                                        </div>}
                                    {selectedInnerValue === 'Exclude Some' &&
                                        <div className='sale-AutoBox'>
                                            <Autocomplete
                                                value={consortiumExclusion}
                                                disabled={isEditable}
                                                {...register('ConsortiumExclusion')}
                                                key='ConsortiumExclusion'
                                                limitTags={2}
                                                id="multiple-limit-tags"
                                                className='sales_autocomplete'
                                                options={consortiumValues}
                                                onFocus={()=>getConsortium()}
                                                getOptionLabel={(options) => options.consortiumName}
                                                renderInput={(params) => (
                                                    <TextField disabled={isEdit} {...params} label='select' />
                                                )}
                                                onChange={(event, values) => {
                                                    const selectedNames = values?.consortiumName
                                                    onChangeHandle(selectedNames, 'ConsortiumExclusion');
                                                    setConsortiumExclusion(values);
                                                }}
                                            />
                                        </div>}
                                </div>}
                                {selectedInnerValue === 'Include Some' &&
                                    <div>
                                        {(salesToDisplay?.AgencyInclusion|| salesToDisplay?.AgencyExclusion) && <div className='d-flex pl-5  '>

                                            <div className='d-flex flex-column salesRadio'>
                                                <Radio disabled={isEditable} label='Include Agencies' {...register('IncludeAgencies')} checked={consortiumIncludeSome == "Include Agencies"} onChange={()=>setConsortiumIncludeSome("Include Agencies")}/>
                                                <Radio disabled={isEditable} label='Exclude Agencies' {...register('ExcludeAgencies')} checked={consortiumIncludeSome == "Exclude Agencies"} onChange={()=>setConsortiumIncludeSome("Exclude Agencies")}/>
                                            </div>
                                            <div>
                                                <Input disabled={isEditable} {...register('AgencyLocationInclusion')} defaultValue={isEdit ? salesToDisplay?.AgencyInclusion ? salesToDisplay?.AgencyInclusion : salesToDisplay?.AgencyExclusion : undefined} name='AgencyLocationInclusion' className="form-control cm-input mt-6 ml-1" onChange={(e)=>onChangeHandle(e?.target?.value,'AgencyLocationInclusion')}/> 
                                            </div>
                                        </div>}
                                        {salesToDisplay?.AgencyLocationInclusion && <div className='d-flex pl-5    mt-4'>
                                            <div className='d-flex flex-column salesRadio SaleAgenciesLocation'>
                                                <Radio disabled={isEditable} label='Include Agencies Location' {...register('IncludeAgenciesLocationRadio')} checked={consortiumIncludeSomeAgencyLocation == "Include Agencies Location"} onChange={()=>setConsortiumIncludeSomeAgencyLocation("Include Agencies Location")}/>
                                            </div>
                                            <div>
                                            <Input
                                                disabled={isEditable}
                                                className="form-control cm-input"
                                                {...register('agencyLocationIncludeSome')} 
                                                defaultValue={isEdit ? salesToDisplay?.AgencyLocationInclusion ? salesToDisplay?.AgencyLocationInclusion : undefined : undefined}                       
                                                onChange={(e) => onChangeHandle(e?.target?.value, "agencyLocationIncludeSome")}
                                            />                                            
                                            </div>

                                        </div>}
                                    </div>}
                            </div>
                        </div>}

                    {selectedValue === "Agency Location" &&
                        <div className='d-flex salesEdit pb-5'>
                            <div className='salesEditcard '>
                                <span className="sales-head-label"> Agency <br />Location </span>
                            </div>
                            <div>

                                { isEdit ? (countryValueInclusion || stateValueInclusion) : true && <div className='salesRight salesRadio d-flex alignCenter justify-between'>
                                    <div className='d-flex flex-column'>
                                        <Radio disabled={isEditable} label='Include Some' onChange={() => handleInnerRadio('Include Some')} checked={selectedInnerValue === 'Include Some'} />
                                        <Radio disabled={isEditable} label='Exclude Some' onChange={() => handleInnerRadio('Exclude Some')} checked={selectedInnerValue === 'Exclude Some'} />
                                    </div>
                                    {selectedInnerValue === 'Include Some' &&
                                        <div className='sale-AutoBox sale-Auto_countryState' >
                                            
                                            <Autocomplete
                                                value={countryValueInclusion}
                                                size="small"
                                                disabled={isEditable}
                                                // {...register('AgencyLocationInclusion')}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value)=>{setCountryValueInclusion(value);getStates(value?.label);}}
                                                options={countryData}
                                                renderInput={(params) => (
                                                    <TextField {...params} label='Country'/>
                                                )}
                                                className='CountryAutocom mb-2 mt-4'
                                            />
                                            <Autocomplete
                                                size="small"
                                                disabled={isEditable || countryValueInclusion == null}
                                                // {...register('AgencyLocationInclusion')}
                                                className='CountryAutocom'
                                                options={states}
                                                value={stateValueInclusion}
                                                getOptionLabel={(options) => options?.label}
                                                renderInput={(params) => (
                                                    <TextField {...params} label='State' />
                                                )}
                                                onChange={(e,value)=>{setStateValueInclusion(value)}}
                                            />
                                        </div>}
                                    {selectedInnerValue === 'Exclude Some' &&
                                        <div className='sale-AutoBox sale-Auto_countryState'>
                                            <Autocomplete
                                                size="small"
                                                value={countryValueExclusion}
                                                disabled={isEditable}
                                                // {...register('AgencyLocationExclusion')}
                                                className='CountryAutocom mb-2 mt-4'
                                                options={countryData}
                                                onChange={(e,value)=>{setCountryValueExclusion(value);getStates(value?.label);}}
                                                getOptionLabel={(options) => options?.label}
                                                renderInput={(params) => (
                                                    <TextField {...params} label='Country' />
                                                )}
                                            />
                                            <Autocomplete
                                                size="small"
                                                disabled={isEditable || countryValueExclusion == null}
                                                // {...register('AgencyLocationExclusion')}
                                                value={stateValueExclusion}
                                                className='CountryAutocom'
                                                options={states}
                                                onChange={(e,value)=>{setStateValueExclusion(value)}}
                                                getOptionLabel={(options) => options?.label}
                                                renderInput={(params) => (
                                                    <TextField {...params} label='State' />
                                                )}
                                            />
                                        </div>}
                                </div>}
                                {selectedInnerValue === 'Include Some' && isEdit ? (salesToDisplay?.AgencyInclusion || salesToDisplay?.AgencyExclusion) : true &&
                                    <div>
                                        <div className='d-flex pl-5 '>

                                            <div className='d-flex flex-column salesRadio ml-1'>
                                                <Radio disabled={isEditable} {...register('AgencyInclusionRadio')} label='Include Agencies' checked={agencyLocationIncludeSome == "Include Agencies"} onChange={()=>setAgencyLocationIncludeSome("Include Agencies")}/>
                                                <Radio disabled={isEditable} {...register('AgencyExclusionRadio')} label='Exclude Agencies' checked={agencyLocationIncludeSome == "Exclude Agencies"} onChange={()=>setAgencyLocationIncludeSome("Exclude Agencies")}/>
                                            </div>
                                            <div className='salesagencies-textfield'>
                                                <Input disabled={isEditable} {...register('AgencyInclusionExclusion')} defaultValue={isEdit ? salesToDisplay?.AgencyInclusion ? salesToDisplay?.AgencyInclusion : salesToDisplay?.AgencyExclusion : undefined} className="form-control cm-input " onChange={(e) => onChangeHandle(e?.target?.value, "AgencyInclusionExclusion")}/>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>}

                    {selectedValue === "Agency" &&
                        <div className='d-flex salesEdit pb-5'>
                            <div className='salesEditcard '>
                                <span className="sales-head-label"> Agency </span>
                            </div>
                            <div>

                                {(isEdit ? (salesToDisplay?.AgencyInclusion || salesToDisplay?.AgencyExclusion) : true) && <div className='salesRight salesRadio d-flex alignCenter justify-between'>
                                    <div className='d-flex flex-column'>
                                        <Radio disabled={isEditable} label='Include Some' onChange={() => handleInnerRadio('Include Some')} checked={selectedInnerValue === 'Include Some'} />
                                        <Radio disabled={isEditable} label='Exclude Some' onChange={() => handleInnerRadio('Exclude Some')} checked={selectedInnerValue === 'Exclude Some'} />
                                    </div>
                                    {selectedInnerValue === 'Include Some' &&
                                        <div>
                                            <Input disabled={isEditable} {...register('AgencyInclusion')} defaultValue={isEdit ? salesToDisplay?.AgencyInclusion : undefined} className="form-control cm-input salesTextFiled ml-2" onChange={(e) => onChangeHandle(e?.target?.value, "AgencyInclusion")}/>
                                        </div>}
                                    {selectedInnerValue === 'Exclude Some' &&
                                        <div>
                                            <Input disabled={isEditable} {...register('AgencyExclusion')} defaultValue={isEdit ? salesToDisplay?.AgencyExclusion : undefined} className="form-control cm-input salesTextFiled " onChange={(e) => onChangeHandle(e?.target?.value, "AgencyExclusion")}/>
                                        </div>}
                                </div>}
                                {selectedInnerValue === 'Include Some' && isEdit ? ( salesToDisplay?.AgentInclusion || salesToDisplay?.AgentExclusion ) : true &&
                                    <div>
                                        <div className='d-flex pl-5 justify-between mt-3'>

                                            <div className='d-flex flex-column salesRadio'>
                                                <Radio disabled={isEditable} {...register('AgnetInclusion')} label='Agent Inclusion' checked={agencyIncludeSome == "Agnet Inclusion"} onChange={()=>setAgencyIncludeSome("Agnet Inclusion")}/>
                                                <Radio disabled={isEditable} {...register('AgentExclusion')} label='Agent Exclusion' checked={agencyIncludeSome == "Agnet Exclusion"} onChange={()=>setAgencyIncludeSome("Agnet Exclusion")}/>
                                            </div>
                                            <div>
                                                <Input disabled={isEditable} {...register('AgnetInclusionExclusion')} defaultValue={isEdit ?(agencyIncludeSome == "Agnet Inclusion" ? salesToDisplay?.AgentInclusion : agencyIncludeSome == "Agnet Exclusion" ? salesToDisplay?.AgentExclusion : undefined) : undefined} className="form-control cm-input salesTextFiled" onChange={(e) => onChangeHandle(e?.target?.value, "AgnetInclusionExclusion")}/>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>}
                </div>
            </div>

            <div className='card mt-2 '>
                <div className="card-body pt-1 pb-4">
                    <div className='cm-col alignCenter m-0'>
                        <span className="cm-head-label mr-4">  Applicable To </span>
                        <Radio disabled={isEditable} label='Booking Channel' {...register('BookingChannelInclusion')} onChange={() => setSelectedValue2('Booking Channel')} checked={selectedValue2 === 'Booking Channel'} />
                        <Radio disabled={isEditable} label='Supplier' {...register('SupplierInclusion')} onChange={() => setSelectedValue2('Supplier')} checked={selectedValue2 === 'Supplier'} />
                        <Radio disabled={isEditable} label=' Carrier' onChange={() => setSelectedValue2('Carrier')} checked={selectedValue2 === 'Carrier'} />
                    </div>

                    {selectedValue2 === 'Booking Channel' &&
                        <div className='d-flex salesEdit'>
                            <div className='salesEditcard py-2'>
                                <span className="sales-head-label">  Booking <br /> Channel </span>
                            </div>
                            <div className='salesRight d-flex salesRadio'>
                                <div>
                                    <CheckBox disabled={isEditable} {...register('online')} label='Online' onChange={()=>setOnlineCheckBox(!onlineCheckBox)} checked={onlineCheckBox}/>
                                    <CheckBox disabled={isEditable} {...register('callCenter')} label='Call Center' onChange={()=>setCallCenterCheckBox(!callCenterCheckBox)} checked={callCenterCheckBox}/>
                                    {/* <CheckBox disabled={isEditable} {...register('api')} label='API' onChange={()=>setApiCheckBox(!apiCheckBox)} checked={apiCheckBox}/> */}
                                    <CheckBox disabled={isEditable} {...register('apiB2B')} label='API-B2B' onChange={()=>setApiB2BCheckBox(!apiB2BCheckBox)} checked={apiB2BCheckBox}/>
                                    <CheckBox disabled={isEditable} {...register('apiB2C')} label='API-B2C' onChange={()=>setApiB2CCheckBox(!apiB2CCheckBox)} checked={apiB2CCheckBox}/>
                                        {/* <>
                                            {SALES_EDIT_FORM_DATA["Second Row"]?.children.map((data: any) => (
                                                <>
                                                        {CREATE_ELEMENT(data, register)}
                                                </>
                                            ))}
                                        </> */}
                                </div>
                            </div>
                        </div>}
                    {selectedValue2 === 'Supplier' &&
                        <div className='d-flex salesEdit'>
                            <div className='salesEditcard py-2'>
                                <span className="sales-head-label"> Supplier </span>
                            </div>
                            <div className='salesRight d-flex salesRadio'>
                                <div>
                                    <CheckBox disabled={isEditable} {...register('saber')} label='Sabre' onChange={()=>setSabreCheckBox(!sabreCheckBox)} checked={sabreCheckBox}/>
                                    <CheckBox disabled={isEditable} {...register('amadeus')} label='Duffel' onChange={()=>setAmadeusCheckBox(!amadeusCheckBox)} checked={amadeusCheckBox}/>
                                    <CheckBox disabled={isEditable} {...register('ndc')} label='Sabre NDC' onChange={()=>setNdcCheckBox(!ndcCheckBox)} checked={ndcCheckBox}/>
                                </div>
                            </div>
                        </div>}
                    {selectedValue2 === 'Carrier' &&
                        <div className='d-flex salesEdit'>
                            <div className='d-flex'>
                                <div >
                                    <div className='salesEditcard py-2'>

                                        <span className="sales-head-label"> Carrier </span>
                                    </div>
                                </div>
                                <div className='salesRight'>
                                    <div>
                                    </div>

                                    {(isEdit ? (salesToDisplay?.OriginInclusion || salesToDisplay?.OriginExclusion) : true) && <div className='d-flex salesRadio'>
                                        <div className='d-flex flex-column'>
                                            <Radio disabled={isEditable} {...register('originInclusion')} label=' Include Specific Origins ' checked={carrierIncludeExcludeSomeOrigins == "Include Specific Origins"} onChange={()=>setCarrierIncludeExcludeSomeOrigins("Include Specific Origins")}/>
                                            <Radio disabled={isEditable} {...register('originExclusion')} label=' Exclude Specific Origins' checked={carrierIncludeExcludeSomeOrigins == "Exclude Specific Origins"} onChange={()=>setCarrierIncludeExcludeSomeOrigins("Exclude Specific Origins")}/>
                                        </div>
                                        <div>
                                            <Input disabled={isEditable} {...register('originInclusionExclusion')} defaultValue={isEdit ? salesToDisplay?.OriginInclusion ? salesToDisplay?.OriginInclusion : salesToDisplay?.OriginExclusion ? salesToDisplay?.OriginExclusion:undefined:undefined} className="form-control cm-input salesTextFiled" onChange={(e) => onChangeHandle(e?.target?.value, "originInclusionExclusion")}/>
                                        </div>
                                    </div>}

                                    {(isEdit ? (salesToDisplay?.DestinationInclusion || salesToDisplay?.DestinationExclusion) : true)  && <div className='d-flex salesRadio'>
                                        <div className='d-flex flex-column'>
                                            <Radio disabled={isEditable} {...register('destinationInclusion')} label='Include Destination(s)' checked={carrierIncludeExcludeDestinations == "Include Destination(s)"} onChange={()=>setCarrierIncludeExcludeDestinations("Include Destination(s)")}/>
                                            <Radio disabled={isEditable} {...register('destinationExclusion')} label='Exclude Destination(s)' checked={carrierIncludeExcludeDestinations == "Exclude Destination(s)"} onChange={()=>setCarrierIncludeExcludeDestinations("Exclude Destination(s)")}/>
                                        </div>
                                        <div>
                                            <Input disabled={isEditable} {...register('destinationInclusionExclusion')} defaultValue={isEdit ? salesToDisplay?.DestinationInclusion ? salesToDisplay?.DestinationInclusion : salesToDisplay?.DestinationExclusion ? salesToDisplay?.DestinationExclusion:undefined:undefined} className="form-control cm-input salesTextFiled ml-3" onChange={(e) => onChangeHandle(e?.target?.value, "destinationInclusionExclusion")}/>
                                        </div>
                                    </div>}

                                    {(isEdit ? salesToDisplay?.TripTypeInclusion?.length : true) && <div>
                                        <CheckBox disabled={isEditable} {...register('allTripTypes')} label='All Trip Types' onChange={()=>setAllTripTypesCheckBox(!allTripTypesCheckBox)} checked={allTripTypesCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('oneWay')} label='One Way' onChange={()=>setOneWayCheckBox(!oneWayCheckBox)} checked={oneWayCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('roundTrip')} label='Round Trip' onChange={()=>setRoundTripCheckBox(!roundTripCheckBox)} checked={roundTripCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('openJaw')} label='Open Jaw' onChange={()=>setOpenJawCheckBox(!openJawCheckBox)} checked={openJawCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('multiCity')} label='Multi-City' onChange={()=>setMultiCityCheckBox(!multiCityCheckBox)} checked={multiCityCheckBox}/>
                                    </div>}
                                    {( isEdit ? salesToDisplay?.CabinType?.length : true) && <div>
                                        <CheckBox disabled={isEditable} {...register('allCabinTypes')} label='All Cabin Types' onChange={()=>setAllCabinTypesCheckBox(!allCabinTypesCheckBox)} checked={allCabinTypesCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('economy')} label='Economy' onChange={()=>setEconomyCheckBox(!economyCheckBox)} checked={economyCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('premiumEconomy')} label='Premium Economy' onChange={()=>setPremiumEconomyCheckBox(!premiumEconomyCheckBox)} checked={premiumEconomyCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('business')} label='Business' onChange={()=>setBusinessCheckBox(!businessCheckBox)} checked={businessCheckBox}/>
                                        <CheckBox disabled={isEditable} {...register('first')} label='First' onChange={()=>setFirstCheckBox(!firstCheckBox)} checked={firstCheckBox}/>
                                    </div>}
                                    {(isEdit ? (salesToDisplay?.BrandedFareInclusion || salesToDisplay?.BrandedFareExclusion) : true) && <div className='d-flex salesRadio'>
                                        <div className='d-flex flex-column'>
                                            <Radio disabled={isEditable} label='Include Brand Code(s)' {...register('includeBrandCode')} checked={carrierIncludeExcludeBrandCode == "Include Brand Code(s)"} onChange={()=>setCarrierIncludeExcludeBrandCode("Include Brand Code(s)")}/>
                                            <Radio disabled={isEditable} label='Exclude Brand Code(s)' {...register('excludeBrandCode')} checked={carrierIncludeExcludeBrandCode == "Exclude Brand Code(s)"} onChange={()=>setCarrierIncludeExcludeBrandCode("Exclude Brand Code(s)")}/>
                                        </div>
                                        <div>
                                            <Input disabled={isEditable} {...register('includeExcludeBrandCode')} defaultValue={isEdit ? salesToDisplay?.BrandedFareInclusion ? salesToDisplay?.BrandedFareInclusion : salesToDisplay?.BrandedFareExclusion ? salesToDisplay?.BrandedFareExclusion : undefined : undefined} className="form-control cm-input salesTextFiled ml-2" onChange={(e) => onChangeHandle(e?.target?.value, "includeExcludeBrandCode")}/>
                                        </div>
                                    </div>}
                                        {(isEdit ? salesToDisplay?.Airline : true) && <div>
                                            <span><Label>Airline</Label></span>
                                            <span>
                                                <Input labelvisible={false} labelName={"Airline"} disabled={isEditable} {...register('Airline')} defaultValue={isEdit ? salesToDisplay?.Airline : undefined} className="form-control cm-input " onChange={(e) => onChangeHandle(e?.target?.value, "Airline")} />
                                            </span>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className='cm-col mt-5 mb-2'>
                <label className="cm-head-label m-0"> Promotional Values</label>
            </div>

                <div className='card'>
                    <div className="card-body">

                        <div className='row cm-col promotion-checkboxes'>

                            <CheckBox disabled={isEditable} {...register('voidAllowed')} label='Void Allowed' onChange={() => setVoidAllowed(!voidAllowed)} checked={voidAllowed} />
                            <CheckBox disabled={isEditable} {...register('refundAllowed')} label='Refund Allowed' onChange={() => setRefundAllowed(!refundAllowed)} checked={refundAllowed} />
                            <CheckBox disabled={isEditable} {...register('reissueAllowed')} label='Reissue Allowed' onChange={() => setReissueAllowed(!reissueAllowed)} checked={reissueAllowed} />
                        </div>
                    </div>
                </div>

            
            {(isEdit ? (salesToDisplay?.VoidFees || salesToDisplay?.TicketingFees || salesToDisplay?.RefundFees || salesToDisplay?.ReissueFees || salesToDisplay?.MerchantFees || salesToDisplay?.BookingFees) : true)  && <><div className='cm-col mt-5 mb-2'>
                <label className="cm-head-label m-0">Fees</label>
            </div>
            <div className='card'>
                <div className="card-body">

                    <div className='row cm-col'>
                        {/* <div className='col-md-2'>  <label className="cm-head-label">Ticketing Fee</label><Input className="form-control cm-input" /></div>
                        <div className='col-md-2'>  <label className="cm-head-label">Void Fee</label><Input className="form-control cm-input" /></div>
                        <div className='col-md-2'>  <label className="cm-head-label">Refund Fee</label><Input className="form-control cm-input" /></div>
                        <div className='col-md-2'>  <label className="cm-head-label">Reissue Fee</label><Input className="form-control cm-input" /></div> */}
                        <>
                        {SALES_EDIT_FORM_DATA["Fifth Row"]?.data.map((data) => (
                            <>{console.log('#########',data?.name,data?.defaultValue)}
                            {<div className='col-md-3 midFontLabel'>
                                {CREATE_ELEMENT(data, register)}
                                </div>}
                            </>
                        ))}
                        </>
                    </div>
                </div> 
            </div></>} 
            {(isEdit ? (salesToDisplay?.AddorRemovePUBCommissionAmount || salesToDisplay?.LowestofDefaultMarkuporthisvalue || salesToDisplay?.AddorRemoveFromDefaultMarkup || sellPUBAtCost) : true) && <><div className='cm-col mt-5 mb-2'>
                <label className="cm-head-label m-0">Commission/Markup</label>
            </div>            
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            {/* First Column */}
                            <Grid item xs={12} md={6}>
                                <div className='cm-col mt-5 mb-2'>
                                    <label className="cm-head-label m-0">PUB Fare</label>
                                </div>
                                <Grid container spacing={2}>
                                    {SALES_EDIT_FORM_DATA["Sixth Row"]?.data.map((data, index) => (<Grid item xs={12} md={6} key={index}>
                                            {CREATE_ELEMENT(data, register)}
                                        </Grid>
                                    ))}
                                    <Grid item style={{ "marginLeft": "1rem", "marginTop": "1.5rem" }}>
                                        <CheckBox disabled={isEditable} {...register('sellPUBAtCost')} label='Sell PUB at cost ' onChange={() => setSellPUBAtCost(!sellPUBAtCost)} checked={sellPUBAtCost} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Second Column */}
                            {(isEdit ? (salesToDisplay?.LowestofDefaultMarkuporthisvalue || salesToDisplay?.AddorRemoveFromDefaultMarkup) : true) && <Grid item xs={12} md={6}>
                                <div className='cm-col mt-5 mb-2'>
                                    <label className="cm-head-label m-0">NET Fare</label>
                                </div>
                                <Grid container spacing={2}>
                                    {SALES_EDIT_FORM_DATA["Seventh Row"]?.data.map((data, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            {CREATE_ELEMENT(data, register)}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>}
                        </Grid>
                    </CardContent>
                </Card></>}
                {/* {JSON.stringify(errors)} */}
            <div className=' d-flex sales-btn'>
                    {
                        loader ?
                            <SpinnerLoader />
                            :
                            <>   {/* Save button is hidden and disabled for update as of now. */}
                                <button hidden={isEdit ? true : isEdit} disabled={(isEdit ? true : isEdit)} className='btn  btn-Save' type='submit'><i className='fas fa-save'></i> Save</button> 
                                <button disabled={isEditable} className='btn btn-Cancel' type='reset' onClick={()=>{if(isEdit){openDialogBox('cancelButton','Are you sure, changes will not be saved?')}else{isEditable(true)}}}><i className="far fa-window-close"></i> Cancel</button>
                            </>
                    }
            </div>
            </form>
            <Dialog
                open={popUp}
                onClose={()=>setPopUp(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="agent-del-popup"
            >
                <div className="agent-del-popup-body">
                    <label>{popUpMessage}</label>
                    <button className="cm-primary-btn mr-3" onClick={(e) => whichButton == 'deleteButton' ? postDeleteSalesPromotionCall(salesNaID) : whichButton == 'cancelButton' ? onBackButton() :  history.push('/SalesPromotion')}>Yes</button>
                    <button className="cm-secondary-btn" onClick={()=>{setPopUp(false);setPopUpMessage('');setWhichButton('')}}>No</button>
                </div>
            </Dialog>
        </>}</>
    )
}

export default SalesEdit