import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Label } from './Label';

interface Option {
  label: string;
  value: string;
  travelBusinessesId?: string;
}

interface Props {
  label: string;
  onChange?: (value: Option | null) => void;
  fetchOptions: (query: string) => Promise<Option[]>;
  debounceTimeout?: number;
  labelClassName?: string;
  divClass?: string;
  disabled?: boolean;
  value: Option | null;
  fetchOnce: boolean;
  defaultOption: Option[]
}

export const CommonAutocomplete: React.FC<Props> = React.memo(({
  label,
  onChange,
  fetchOptions,
  debounceTimeout = 300,
  labelClassName = '',
  divClass = '',
  disabled = false,
  value=null,
  fetchOnce = false,
  defaultOption = []
}) => {
//   const [value, setValue] = useState<Option | null>(null);
  const [options, setOptions] = useState<Option[]>(fetchOnce ? defaultOption : []);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;

    const handleInputChange = (input: string) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(async () => {
        const fetchedOptions = await fetchOptions(input);
        setOptions(fetchedOptions);
      }, debounceTimeout);
    };
    
    if (!fetchOnce) {
      if (inputValue.trim() !== '') {
        handleInputChange(inputValue);
      } else {
        setOptions([]);
      }
    }

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [fetchOptions, inputValue, debounceTimeout]);

  const handleOnChange = (event: React.ChangeEvent<{}>, newValue: Option | null) => {
    
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={divClass}>
      <Label className={labelClassName}>{label}</Label>
      <Autocomplete
        disabled={disabled}
        size="small"
        value={value}
        onChange={(event,value)=>handleOnChange(event,value)}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onChange={(event) => setInputValue(event.target.value)}
          />
        )}
      />
    </div>
  );
});

